import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import styled from "styled-components";
import SEO from "../components/SEO";
import {
  CustomButtonSmall,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
  PageSectionWrapper,
} from "../components/common";

const PlayPageArticle = styled(PageSectionArticle)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
`;

const PlayPageUnityContainer = styled.div`
  margin-bottom: 40px;
`;

function Play() {
  const { unityProvider, isLoaded, loadingProgression, requestFullscreen } = useUnityContext({
    loaderUrl: "../PluggaMedBilder/Build/pmb.loader.js",
    dataUrl: "../PluggaMedBilder/Build/pmb.data",
    frameworkUrl: "../PluggaMedBilder/Build/pmb.framework.js",
    codeUrl: "../PluggaMedBilder/Build/pmb.wasm",
  
    // loaderUrl: "../test2.loader.js",
    // dataUrl: "../test2.data",
    // frameworkUrl: "../test2.framework.js",
    // codeUrl: "../test2.wasm",  
  });


      // We'll round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.
  const loadingPercentage = Math.round(loadingProgression * 100);

  const onFullScreen = () => {
    requestFullscreen(true);
  };

  return (
    <>
      <SEO title="Spela" />
      <PageSectionWrapper>
        <PageSection bgColor="var(--creamWhite)">
          <PageSectionBackdrop bgColor="var(--creamWhite)">
            <PlayPageArticle>

            <div className="container">
      {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="loading-overlay">
          <p>Laddar... ({loadingPercentage}%)</p>
        </div>
      )}
      <Unity className="unity" unityProvider={unityProvider} style={{ width: 1000, height: 600 }}  />
    </div>

    <CustomButtonSmall btnColor="white" onClick={onFullScreen}>
                  Helskärm
                </CustomButtonSmall>

  {/* <Unity unityProvider={unityProvider}  style={{ width: 800, height: 600 }} /> */}
           </PlayPageArticle>
           </PageSectionBackdrop>
         </PageSection>
       </PageSectionWrapper>
     </> 
)}

export default Play;

// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import SEO from "../components/SEO";
// import Unity, { UnityContent } from "react-unity-webgl";
// import {
//   CustomButtonSmall,
//   PageSection,
//   PageSectionArticle,
//   PageSectionBackdrop,
//   PageSectionWrapper,
// } from "../components/common";

// const PlayPageArticle = styled(PageSectionArticle)`
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 100px 0;
// `;

// const PlayPageUnityContainer = styled.div`
//   margin-bottom: 40px;
// `;

// const Play = () => {
//   const [unityContent, setUnityContent] = useState(null);
//   const [progression, setProgression] = useState(0);
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     setUnityContent(
//       new UnityContent(
//         "../PluggaMedBilder/Build/pmb_20211024_001.json",
//         "../PluggaMedBilder/Build/UnityLoader.js"
//       )
//     );
//   }, []);

//   useEffect(() => {
//     if (unityContent) {
//       unityContent.on("progress", (progressionVal) => {
//         setProgression(Math.round(progressionVal * 100));
//       });
//     }
//   }, [unityContent]);

//   useEffect(() => {
//     if (progression >= 100) {
//       setIsLoaded(true);
//     }
//   }, [progression]);

//   const onFullScreen = () => {
//     unityContent.unityInstance.SetFullscreen(true);
//   };

//   return (
//     <>
//       <SEO title="Spela" />
//       <PageSectionWrapper>
//         <PageSection bgColor="var(--creamWhite)">
//           <PageSectionBackdrop bgColor="var(--creamWhite)">
//             <PlayPageArticle>
//               {!isLoaded && <span>{progression}%</span>}
//               <PlayPageUnityContainer
//                 style={{ ...(!isLoaded && { visibility: "hidden" }) }}
//               >
//                 {unityContent && <Unity unityContent={unityContent} />}
//               </PlayPageUnityContainer>
//               {isLoaded && (
//                 <CustomButtonSmall btnColor="white" onClick={onFullScreen}>
//                   Helskärm
//                 </CustomButtonSmall>
//               )}
//             </PlayPageArticle>
//           </PageSectionBackdrop>
//         </PageSection>
//       </PageSectionWrapper>
//     </>
//   );
// };

// export default Play;




// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import SEO from "../components/SEO";
// import { Unity, useUnityContext } from "react-unity-webgl";
// import {
//   CustomButtonSmall,
//   PageSection,
//   PageSectionArticle,
//   PageSectionBackdrop,
//   PageSectionWrapper,
// } from "../components/common";

// const PlayPageArticle = styled(PageSectionArticle)`
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 100px 0;
// `;

// const PlayPageUnityContainer = styled.div`
//   margin-bottom: 40px;
// `;

// const Play = () => {
//   const [unityContent, setUnityContent] = useState(null);
//   const [progression, setProgression] = useState(0);
//   const [isLoaded, setIsLoaded] = useState(true);

//   // useEffect(() => {
//   //   // setUnityContent(
//   //     const { unityProvider } = useUnityContext({
//   //       loaderUrl: "build/myunityapp.loader.js",
//   //       dataUrl: "build/myunityapp.data",
//   //       frameworkUrl: "build/myunityapp.framework.js",
//   //       codeUrl: "build/myunityapp.wasm",
//   //     });
//   //   })
//   // }, []);

//   // useEffect(() => {
//   //   if (unityContent) {
//   //     unityContent.on("progress", (progressionVal) => {
//   //       setProgression(Math.round(progressionVal * 100));
//   //     });
//   //   }
//   // }, [unityContent]);

//   // useEffect(() => {
//   //   if (progression >= 100) {
//   //     setIsLoaded(true);
//   //   }
//   // }, [progression]);

//   // const onFullScreen = () => {
//   //   unityContent.unityInstance.SetFullscreen(true);
//   // };

//   return (
//     <>
//       <SEO title="Spela" />
//       <PageSectionWrapper>
//         <PageSection bgColor="var(--creamWhite)">
//           <PageSectionBackdrop bgColor="var(--creamWhite)">
//             <PlayPageArticle>
//               {!isLoaded && <span>{progression}%</span>}
//               <PlayPageUnityContainer
//                 style={{ ...(!isLoaded && { visibility: "hidden" }) }}
//               >
//                 {unityContent && <Unity unityContent={unityContent} />}
//               </PlayPageUnityContainer>
//               {isLoaded && (
//                 <CustomButtonSmall btnColor="white" onClick={onFullScreen}>
//                   Helskärm
//                 </CustomButtonSmall>
//               )}
//             </PlayPageArticle>
//           </PageSectionBackdrop>
//         </PageSection>
//       </PageSectionWrapper>
//     </>
//   );
// };

// export default Play;
